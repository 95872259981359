@import url("https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap");
.App {
  text-align: center;
  width: 100vw;
}
.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}
ul {
  list-style: none;
}
* {
  font-feature-settings: "clig" off, "liga" off;
  font-family: Urbanist;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
body {
  margin: 0;
  overflow-x: hidden;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.urbanist-font {
  font-family: "Urbanist", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}
.header {
  display: flex;
  align-items: flex-start;
  padding: 1rem 2rem;
  background-color: #fef6f5;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
}

.logo {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.logo img {
  width: 300px;
}

.nav {
  flex: 6 1;
  display: flex;
  justify-content: center;
}

.nav ul {
  list-style: none;
  display: flex;
  gap: 2rem;
  margin: 0;
  padding: 0;
}

.nav a {
  text-decoration: none;
  color: #333;
  font-size: 1rem;
}

.lang {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  position: relative;
}

.lang-btn {
  background: url("assets/language.png") no-repeat center center;
  background-size: contain;
  width: 20px;
  height: 20px;
  border: none;
  cursor: pointer;
}

.lang .dropdown {
  position: absolute;
  margin-top: 30px;
  background: white;
  border: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  color: black;
  justify-content: flex-start;
  z-index: 10;
}
.dropdown-btn {
  color: black;
}
.lang .dropdown button {
  background: none;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  text-align: left;
  width: 100%;
}

.lang .dropdown button:hover {
  background-color: #f0f0f0;
}

.menu-icon {
  display: none;
}

.menu-icon img {
  width: 30px;
}
@media (min-width: 1800px) {
  .contact-us {
    margin-right: 20% !important;
    margin-left: 20% !important;
  }
  .rect {
    right: 19.2% !important;
  }
}
@media (min-width: 600px) and (max-width: 900px) {
  .fram-shadow {
    margin-top: -10vh !important;
  }
}
@media (max-width: 768px) {
  .policies {
    border-right: unset !important;
  }
  .terms {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .nav ul {
    display: none;
  }

  .lang {
    display: none;
  }
  .logo img {
    width: 200px;
  }
  .menu-icon {
    display: block;
  }
  .footer-content {
    flex-direction: column;
    justify-content: space-between;
  }
  .logo {
    margin-right: unset !important;
    margin-left: unset !important;
  }
  .contact-map {
    display: none !important;
  }
  .contact-us {
    margin-right: unset !important;
    margin-left: unset !important;
    margin-bottom: unset !important;
    margin-top: unset !important;
  }
  .rect {
    display: none !important;
  }
  .shadow-image {
    display: none !important;
  }
  .logo-TT {
    display: none !important;
  }
  .fram-shadow {
    margin-top: unset !important;
    z-index: unset !important;
  }
  .content {
    padding-left: 50px !important;
    padding-right: 50px !important;
    height: 250px !important;
  }
  .shadow-box {
  }
  .tit {
    font-size: 44px !important;
    margin-left: unset !important;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.hero-section {
  position: relative;
  width: 100%;
  max-width: 1000px;
  margin: auto;
}
.hero-section2 {
  position: relative;
  background-image: url(./assets/term-image.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 500px;
  width: 100vw;
  margin: auto;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main-container {
  position: relative;
}

.main-image {
  width: 100%;
  display: block;
}

.main-content {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
}

.overlay {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.textcont {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-text-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.logo-T {
  width: 70px;
  height: 70px;
  background-image: url(assets/spin-logo.svg);
  background-size: contain;
  margin-right: 10px;
  animation: spin 4s linear infinite;
  animation-timing-function: ease-in-out;
  transform-origin: center;
}
.logo-TT {
  margin-top: 40px;
  width: 70px;
  height: 70px;
  margin-bottom: 20px;
  margin-right: 10px;
  animation: spin 4s linear infinite;
  animation-timing-function: ease-in-out;
  transform-origin: center;
}
.logo-f {
  width: 50px;
  height: 50px;
  animation: spin 4s linear infinite;
  animation-timing-function: ease-in-out;
}
.logo-titile {
}
.text-L {
  font-size: 1.3rem;
  font-weight: bold;
  color: white;
  color: #fff;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  /* font-size: 36.471px; */
  font-style: normal;
  font-weight: 600;
  line-height: 96%; /* 35.012px */
  letter-spacing: 0.447px;
}
.logo-con {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.logo-con-text {
  color: #fe738d !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-family: Poppins !important;
  font-size: 36.471px !important;
  font-style: normal !important;
  font-weight: 300 !important;
  line-height: 96% !important;
  letter-spacing: -2.553px !important;
}
.text-logo {
  font-size: 3.6rem;
  color: #fff;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Serif";
  font-style: normal;
  line-height: 96%;
  letter-spacing: -2.68px;
}

@media (max-width: 768px) {
  .left{
    justify-content: center !important;
    align-items: center !important;;
  }
  .update-info-content{
    flex-direction: column !important;
  }
  .downloadBanner{
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .tutorials-container{
    flex-direction: column;
  }
  .main-image {
    height: 350px;
    /* margin-bottom: 20px; */
  }
  .overlay {
    bottom: 10px;
    padding: 5px 10px;
    box-shadow: unset !important ;
  }
  .logo-text-container {
    flex-direction: column;
    text-align: center;
  }
  .text-logo {
    font-size: 1.6rem;
    line-height: 96%;
    letter-spacing: 0.68px;
  }
  .logo-T {
    margin-bottom: 10px;
    margin-top: 10px;
  }
}
.shadow-box {
  width: 100%;
  background-color: #ffe8e8;
  z-index: 3;
  margin: auto;
  overflow: hidden;
}
.fram-shadow {
  width: 100vw;
}
.shadow-image {
  width: 100%;
  margin: auto;
}

.fram-shadow {
  position: absolute;
  margin-top: -10vh;
  z-index: -2;
  /* margin: auto; */
}
.upper-curve {
  transform: translateY(10px);
}
.content-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.content {
  height: 100px;
  color: #6c6c6c;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Urbanist;
  font-size: 1.5rem;
  font-style: normal;
  padding-left: 130px;
  padding-right: 130px;
  padding-bottom: 20px;
  font-weight: 400;
  line-height: 100%; /* 30.72px */
  letter-spacing: 0.28px;
  scrollbar-width: none;
}
.titile {
  color: #fe738d;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: 36.471px;
  font-style: normal;
  font-weight: 600;
  line-height: 96%; /* 35.012px */
  letter-spacing: -2.553px;
}
/* src/Services.css */
.services {
  text-align: center;
  padding: 25px;
  margin-top: 337px;
}

.services-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.service {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff8f8;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  width: 600px;
  max-width: 100%;
  text-align: left;
}

.service img {
  max-width: 150px;
  border-radius: 10px;
}

.service-text {
  flex: 1;
  margin-right: 1rem;
}

.service h3 {
  margin: 0 0 0.5rem;
}

.service p {
  color: #555;
  margin: 0;
}

.tit {
  text-align: left;
  margin-left: 100px;
  color: #ff677e;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Urbanist;
  font-size: 64px;
  font-style: normal;
  font-weight: 900;
  line-height: 96%; /* 61.44px */
  letter-spacing: -2.56px;
}

/* src/ContactUs.css */
.contact-us {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 9rem; */
  /* border-radius: 20px; */
  /* background-color: #edcaca; */
  margin-right: 20%;
  margin-left: 20%;
  margin-bottom: 100px;
  margin-top: 100px;
  height: 800px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.rect {
  margin-bottom: 100px;
  margin-top: 100px;
  right: 19.2%;
  position: absolute;
  width: 17%;
  height: 800px;
  background-color: #ff677e;
  z-index: -2;
  margin-bottom: 100px;
  margin-top: 100px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-left-radius: 0;
}
.contact-form {
  flex: 1;
  max-width: 400px;
  padding: 2rem;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  /* background-color: #ecdcdc; */
}

.contact-title {
  font-size: 2rem;
  font-weight: bold;
  color: #ff677e;
  display: flex;
  align-items: center;
}

.contact-icon {
  font-size: 2.5rem;
  margin-right: 0.5rem;
}

.contact-description {
  text-align: left;
  margin: 1rem 0;
  color: #555;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  font-size: 1rem;
  background-color: #ffffff;
}

.form-group input::placeholder,
.form-group textarea::placeholder {
  color: #aaa;
}

.form-group textarea {
  height: 100px;
  resize: none;
}

button {
  background-color: #dd5471;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 1rem;
  display: block;
  width: 107%;
}

button:hover {
  /* background-color: #FF677E
  ; */
}

.contact-map {
  flex: 1;
  max-width: 500px;
  display: flex;
  justify-content: center;
  padding: 3.04rem;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-left-radius: 0;
}

.contact-map img {
  max-width: 80%;
  border-radius: 20px;
}
.footer {
  background-color: #fff8f8;
  color: #333;
  padding: 20px 0;
}

.footer-content {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.logo {
  text-align: center;
  margin-right: 20px;
  margin-left: 100px;

  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
  text-align: left;
}

.logo-footer {
  text-align: center;

  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
  text-align: left;
}
.logo-footer img {
  /* width: 280px; */
  padding-bottom: 0px;
}
.logo-footer p {
  width: 300px;
  padding-top: 3px;

  padding-left: 20px;
  color: #b7b7b7;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Urbanist;
  font-size: 32px;
  font-style: normal;
  font-weight: 200;
  line-height: 96%; /* 40.32px */
  letter-spacing: -1.26px;
}

.logo img {
  width: 280px;
  height: auto;
}
.logo p {
  color: #b7b7b7;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Urbanist;
  font-size: 42px;
  font-style: normal;
  font-weight: 200;
  line-height: 96%; /* 40.32px */
  letter-spacing: -1.26px;
}
.logo-text {
  font-size: 24px;
  font-weight: bold;
  color: #e74c3c;
}

.links {
  display: flex;
  justify-content: space-around;
  flex: 1;
}

.link-section {
  padding: 0 20px;
  text-align: left;
}

.link-section h4 {
  margin-bottom: 10px;
}

.link-section ul {
  list-style: none;
  padding: 0;
}

.link-section ul li {
  margin-bottom: 5px;
}

.link-section ul li a {
  color: #333;
  text-decoration: none;
}

.link-section ul li a:hover {
  text-decoration: underline;
}

.ultimed {
  border-right: 1px solid #ccc;
}

.policies {
  border-right: 1px solid #ccc;
}

.social-icons {
  display: flex;
  margin-bottom: 10px;
}

.social-icons a {
  color: #333;
  font-size: 24px;
  margin-right: 10px;
  text-decoration: none;
}

social-icons a:hover {
  color: #e74c3c;
}

.contact-info p {
  margin: 5px 0;
}

.footer-bottom {
  text-align: center;
  margin-top: 20px;
  color: #afafaf;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 96%; /* 21.12px */
  letter-spacing: -0.88px;
}
.terms {
  color: #000;
  /* font-feature-settings: "liga" off, "clig" off; */
  /* font-family: Urbanist; */
  font-size: 15px !important;
  font-style: normal;
  font-weight: 400;
  /* line-height: 96%;  */
  /* letter-spacing: -1.12px; */
  padding-left: 90px;
  padding-right: 90px;
  padding-bottom: 50px;
  padding-top: 50px;
  text-align: left;
}
.welcome {
  color: #000;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Roboto Serif";
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 96%; /* 46.08px */
  letter-spacing: -1.92px;
}
.terms-of-servicse {
  margin-top: -20px;
}
.menu-dropdown {
  position: absolute;
  top: 58px;
  right: 0;
  background: white;
  border: 1px solid #ddd;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.menu-dropdown.show {
  opacity: 1;
  transform: translateX(0px);
}

.menu-dropdown ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu-dropdown ul li {
  margin: 0;
}

.menu-dropdown ul li a,
.menu-dropdown ul li button {
  display: block;
  width: 100%;
  padding: 10px;
  text-align: left;
  background: none;
  border: none;
  text-decoration: none;
  color: #333;
  cursor: pointer;
}

.menu-dropdown ul li a:hover,
.menu-dropdown ul li button:hover {
  background: #f0f0f0;
}

.menu-btn {
  background: none;
  border: none;
  cursor: pointer;
}

hr {
  margin: 10px 0;
  border: none;
  border-top: 1px solid #ddd;
}

.dropdown-btn {
  display: flex;
  align-items: center;
}

.dropdown-btn img {
  margin-left: 10px;
  width: 20px;
  height: auto;
}
/* src/components/Communications.css */
.communications-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(241px, 3fr));
  max-width: 840px; /* Ensure maximum width allows for 3 columns */
  margin: 0 auto; /* Center the grid */
  gap: 20px;
  padding: 51px;
  margin-bottom: 100px;
}

.card {
  background-color: #ffe6e6;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.card div {
  width: 73px;
  height: 73px;
  /* margin-bottom: 12px; */
}

.icon-communications {
  background: url("./assets/l-img1.svg") no-repeat center center;
  background-size: cover;
}
.icon-vacation {
  background: url("./assets/l-img2.svg") no-repeat center center;
  background-size: cover;
}
.icon-pass-tray {
  background: url("./assets/l-img3.svg") no-repeat center center;
  background-size: cover;
}
.icon-admin-changes {
  background: url("./assets/l-img1.svg") no-repeat center center;
  background-size: cover;
}
.icon-medlink-update {
  background: url("./assets/l-img3.svg") no-repeat center center;
  background-size: cover;
}
.icon-abatnex-info {
  background: url("./assets/l-img2.svg") no-repeat center center;
  background-size: cover;
}
.icon-service-guide {
  background: url("./assets/l-img3.svg") no-repeat center center;
  background-size: cover;
}
.icon-medicine-shipment {
  background: url("./assets/l-img2.svg") no-repeat center center;
  background-size: cover;
}
.icon-medicate-program {
  background: url("./assets/l-img1.svg") no-repeat center center;
  background-size: cover;
}
.icon-service-update {
  background: url("./assets/l-img1.svg") no-repeat center center;
  background-size: cover;
}
.icon-medcycle-service {
  background: url("./assets/l-img2.svg") no-repeat center center;
  background-size: cover;
}
.icon-feedback {
  background: url("./assets/l-img3.svg") no-repeat center center;
  background-size: cover;
}

.card p {
  color: #ef738e !important;
  font-size: 27px !important;
  height: 123px;
  /* margin: 0 0 12px 0; */
  font-size: 16px;
}
.Latest-t {
  margin-left: 13%;
  margin-right: 13%;
  font-size: 37px;
  text-align: center;
  color: #ef738e !important;
  margin-top: 74px;
}
.communications-grid .card button {
  position: relative;
  background-color: #ff6666;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  overflow: hidden; /* Ensures the exclamation mark doesn't overflow the button */
}

.communications-grid .card button span {
  transition: opacity 0.3s;
}

.communications-grid .card button::before {
  content: "\FE15";
  position: absolute;
  left: 50%;
  top: -50%; /* Start above the button */
  transform: translate(-50%, 0%);
  font-size: 34px; /* Increase the font size for the ! sign */
  color: white;
  font-weight: bold;
  opacity: 0; /* Initially hidden */
  transition: top 0.3s, opacity 0.3s; /* Smooth transition for both top and opacity */
}

.communications-grid .card button:hover::before {
  top: 50%; /* Move to the center */
  opacity: 1; /* Make it visible */
  transform: translate(-50%, -50%); /* Center align */
}

.communications-grid .card button:hover span {
  opacity: 0; /* Hide the text */
}

.card button {
  background-color: #ff6666;
  color: white;
  border: none;
  border-radius: 0px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;
  width: 170px;
}
/* 
.card button:hover {
  background-color: #ff3333;
} */
.tutorials-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex-wrap: nowrap;
  align-content: stretch;
}
.left {
  display: flex;
  flex-direction: column;
  gap: 14px;
  align-items: center;
}
.app-image {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 444px;
  width: 202px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-image: url(./assets//app1.png);

}
.app2 {
  background-image: url(./assets//app2.png) !important;

}
.tutorials-content {
  max-width: 600px;
  margin-left: 20px;
  text-align: left;
}

.tutorials-content h2 {
  font-size: 24px;
  font-weight: bold;
}

.tutorials-content h3 {
  font-size: 20px;
  margin-top: 10px;
}

.tutorials-content h4 {
  line-height: 27px;
  font-size: 25px;
  margin-top: 10px;
}

.tutorials-content p {
  margin-top: 15px;
}

.tutorials-content ul {
  margin-top: 15px;
  list-style-type: disc;
  padding-left: 39px;
}

.tutorials-content li {
  margin-top: 5px;
}
.download-app-container {
  background-image: url();
  /* background-color: rgba(242, 242, 242, 1) !important;; */
  padding: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  margin-top: 100px;
  margin-bottom: 100px;
  box-shadow: rgba(0, 0, 0, 0.25) 0 0 11px 0 !important;
}

.download-app-content {
  max-width: 300px;
  text-align: left;
  display: flex;
}
.wrap-app {
  display: flex;
  max-width: 500px;
  flex-direction: column;
}
.download-app-content h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.instructions p {
  font-size: 18px;
  margin-bottom: 10px;
}
.instructions{
  text-align: left;
}
.instructions ul {
  list-style-type: disc;
  padding-left: 20px;
  text-align: left;
}

.instructions a {
  color: blue;
  text-decoration: underline;
}

.download-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.download-button {
  background-color: white;
  border: 2px solid #ccc;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.download-button.ios-button {
  color: #fe738d;
}

.download-button.android-button {
  color: #ef738e;
}

.download-button:hover {
  background-color: #ccc;
}
.update-info-container {
  display: flex;
  justify-content: center;
  padding: 20px;
  background-color: #ffffff;
}

.update-info-content {
  display: flex;
  max-width: 1200px;
  align-items: center;
}

.update-info-image img {
  width: 300px; /* Adjust the width as needed */
  height: auto;
}

.update-info-text {
  margin-left: 40px;
  max-width: 600px;
  text-align: left;
}

.update-info-text h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.update-info-text p {
  font-size: 18px;
  margin-bottom: 10px;
}

.update-info-text ul {
  list-style-type: none;
  padding-left: 0;
}

.update-info-text ul li {
  font-size: 18px;
  margin-bottom: 15px;
}

.update-info-text ul li strong {
  font-weight: bold;
}

.update-info-text p:last-child {
  font-style: italic;
  margin-top: 20px;
}
.accordion-container {
  max-width: 800px; /* Set the max width for the accordion */
  margin: 20px auto; /* Center the accordion horizontally and give some vertical spacing */
  /* padding: 10px; */
}
.wraper{
  margin-bottom: 20px;
  background-color: rgba(242, 242, 242, 1) !important;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.wraper2{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: baseline;

}
.wraper3{
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: baseline;

}
.accordion-title {
  border-radius: unset !important;
  background-color: unset !important;
  color: hsl(0, 0%, 0%) !important;
  padding: 15px 20px; /* Padding for spacing around text */
  font-size: 18px; /* Larger font size for titles */
  border: none; /* No border for a clean look */
  width: 100%; /* Title buttons take full width of container */
  text-align: left; /* Left align text */
  outline: none; /* Hide the outline */
  cursor: pointer; /* Cursor indicates the title is clickable */
}
.accordion-arrow {
  background-image: url(./assets/arrow.svg);
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: unset !important;
  background-color: unset !important;
  color: #000 !important;
  font-size: 18px; /* Larger font size for titles */
  border: none; /* No border for a clean look */
  width: 15px; /* Title buttons take full width of container */
  text-align: right; /* Left align text */
  cursor: pointer; /* Cursor indicates the title is clickable */
}

.accordion-title:hover {
}

.accordion-title:focus {
  outline: none; /* Ensure focus does not alter look */
}

.accordion-content {
  overflow: hidden; /* Hide the overflow content */
  transition: max-height 0.2s ease-out; /* Smooth transition for the expanding effect */
  padding: 0 20px; /* Padding on sides of content */
  font-size: 16px; /* Font size for content */
  color: #333; /* Darker text for readability */
  text-align: left;
}

.accordion-content p {
  margin: 15px 0; /* Margin top and bottom for paragraph */
}
.accordion{
  margin-top: 100px;
  margin-bottom: 100px;
}
.tutorials{
  /* margin-top: 150px; */
}
.tutorial
{
  background-image: url(./assets/t-img.svg);
  height: 480px;
  width: 100% !important;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  border-radius: 30px;
}
.text-info{
  color: #fe738d !important;
}
.downloadBanner {
  background: url('./assets/t-bg.svg') center center no-repeat;
  background-size: cover;
  text-align: center;
  padding: 50px;
  color: #000000;
  border-radius: 30px;
  margin-left: 100px;
  margin-right: 100px;
  margin-bottom: 80px;
  margin-top: 80px;

}

.downloadBanner h1 {
  font-size: 2em;
  margin-bottom: 20px;
}

.buttons {
  display: flex;
  flex-direction: row;
  margin: 20px 0;
  justify-content: center;
}

.googlePlayButton {
  text-decoration: none;
  display: inline-block;
  color: rgb(19, 18, 18);
  background-image: url(./assets/googleplay.svg);
  /* padding: 10px 20px; */
  width: 125px;
  height: 40px;
  margin: 5px;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-size: contain;
}
.appStoreButton {
  text-decoration: none;
  display: inline-block;
  color: rgb(19, 18, 18);
  background-image: url(./assets/appstore.svg);
  width: 125px;
  height: 40px;
  margin: 5px;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-size: contain;
}
.infoText {
  font-size: 0.9em;
  margin-top: 20px;
}

.footer1 {
  font-size: 0.8em;
  opacity: 0.6;
  margin-top: 30px;
  color: #fe738d; ;
}
